import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Card,
  CardContent,
  Pagination,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Alert,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import dayjs from "dayjs";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  FilterList as FilterListIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  DateRange as DateRangeIcon,
} from "@mui/icons-material";

const ActiveCarsPage = () => {
  const [plateOccurrences, setPlateOccurrences] = useState({});
  const [inactivePlates, setInactivePlates] = useState([]);
  const [inactiveDates, setInactiveDates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterPlate, setFilterPlate] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const platesPerPage = 5;

  const collections = {
    tickets_getout: "Modjo",
    tickets_getout_Alemgena: "Alemgena",
    tickets_getout_sebeta: "Sebeta",
    tickets_getout_CheffeDonsa: "Cheffe-Donsa",
    tickets_getout_leman: "Lemmen",
    tickets_getout_welliso: "Welliso",
    tickets_getout_Tajji: "Tajji",
  };

  const generateDateRange = (start, end) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const dates = [];

    let currentDate = startDate;
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  };

  const transformPlateNumber = (plateNumber) => {
    const formattedNumber = plateNumber.replace(/^\d{0,3}/, "3");
    return formattedNumber.includes("-")
      ? formattedNumber
      : `3-${formattedNumber}`;
  };

  useEffect(() => {
    const fetchOccurrences = async () => {
      setIsLoading(true);
      let occurrences = {};
      let activeDatesMap = {};

      try {
        const [startDate, endDate] = dateRange;
        const rangeDates =
          startDate && endDate
            ? generateDateRange(startDate, endDate)
            : [dayjs().format("YYYY-MM-DD")];

        for (const [collectionName, routeName] of Object.entries(collections)) {
          if (selectedRoute && routeName !== selectedRoute) continue;

          for (const date of rangeDates) {
            const docRef = doc(db, collectionName, date);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const data = docSnap.data();
              Object.values(data).forEach((ticket) => {
                const plateNumber = ticket["Plate Number"];
                if (
                  plateNumber &&
                  (!filterPlate || plateNumber.includes(filterPlate))
                ) {
                  occurrences[plateNumber] = {
                    count: (occurrences[plateNumber]?.count || 0) + 1,
                    route: routeName,
                  };

                  activeDatesMap[plateNumber] = [
                    ...(activeDatesMap[plateNumber] || []),
                    date,
                  ];
                }
              });
            }
          }
        }

        setPlateOccurrences(occurrences);
        await fetchInactivePlates(
          Object.keys(occurrences),
          rangeDates,
          activeDatesMap
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOccurrences();
  }, [dateRange, filterPlate, selectedRoute]);

  const fetchInactivePlates = async (
    activePlates,
    rangeDates,
    activeDatesMap
  ) => {
    try {
      const routesRef = collection(db, "Routes");
      const routesSnapshot = await getDocs(routesRef);

      let allRoutePlates = [];
      routesSnapshot.forEach((doc) => {
        const data = doc.data();
        allRoutePlates = allRoutePlates.concat(data.plateNumbers || []);
      });

      const inactive = allRoutePlates.filter(
        (plate) => !activePlates.includes(plate)
      );

      let inactiveDateMap = {};
      inactive.forEach((plate) => {
        const inactiveDates = rangeDates.filter(
          (date) => !activeDatesMap[plate]?.includes(date)
        );
        inactiveDateMap[plate] = inactiveDates;
      });

      setInactivePlates(inactive);
      setInactiveDates(inactiveDateMap);
    } catch (error) {
      console.error("Error fetching inactive plates:", error);
      setError(error.message);
    }
  };

  const activePlates = Object.entries(plateOccurrences)
    .sort(([, a], [, b]) => b.count - a.count)
    .slice((activePage - 1) * platesPerPage, activePage * platesPerPage);

  const inactivePlatesPaginated = inactivePlates.slice(
    (inactivePage - 1) * platesPerPage,
    inactivePage * platesPerPage
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box p={3}>
        <Typography variant="h4" gutterBottom align="center" sx={{ color: '#1976d2' }}>
          Active Cars Management
        </Typography>

        {/* Filters Section */}
        <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ color: '#1976d2' }}>
              <FilterListIcon sx={{ mr: 1 }} /> Filters
            </Typography>
            <Box display="flex" gap={2} flexWrap="wrap" alignItems="center">
              <Tooltip title="Select date range" placement="top">
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={dateRange}
                  onChange={(newValue) => setDateRange(newValue)}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} />
                      <Box mx={2}>to</Box>
                      <TextField {...endProps} />
                    </>
                  )}
                />
              </Tooltip>
              <Tooltip title="Filter by plate number" placement="top">
                <TextField
                  label="Plate Number"
                  variant="outlined"
                  value={filterPlate}
                  onChange={(e) => setFilterPlate(e.target.value)}
                  sx={{ minWidth: 200 }}
                />
              </Tooltip>
              <Tooltip title="Filter by route" placement="top">
                <TextField
                  select
                  label="Route"
                  variant="outlined"
                  value={selectedRoute}
                  onChange={(e) => setSelectedRoute(e.target.value)}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem value="">All Routes</MenuItem>
                  {Object.values(collections).map((route) => (
                    <MenuItem key={route} value={route}>
                      {route}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
              <Tooltip title="Clear filters" placement="top">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ClearIcon />}
                  onClick={() => {
                    setDateRange([null, null]);
                    setFilterPlate("");
                    setSelectedRoute("");
                  }}
                >
                  Clear Filters
                </Button>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>

        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Active Plates Section */}
            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Active Plate Numbers ({Object.keys(plateOccurrences).length})
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {dateRange[0] && dateRange[1]
                    ? `${Object.keys(plateOccurrences).length} Occurrences from ${dayjs(
                        dateRange[0]
                      ).format("YYYY-MM-DD")} to ${dayjs(dateRange[1]).format(
                        "YYYY-MM-DD"
                      )}`
                    : "Showing all dates."}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {activePlates.map(([plateNumber, { count, route }]) => (
                        <TableRow key={plateNumber}>
                          <TableCell>{transformPlateNumber(plateNumber)}</TableCell>
                          <TableCell>{route}</TableCell>
                          <TableCell>{count} Occurrences</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={Math.ceil(Object.keys(plateOccurrences).length / platesPerPage)}
                  page={activePage}
                  onChange={(_, value) => setActivePage(value)}
                  sx={{ mt: 2 }}
                  shape="rounded"
                />
              </CardContent>
            </Card>

            {/* Inactive Plates Section */}
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Inactive Plate Numbers ({inactivePlates.length})
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {inactivePlatesPaginated.map((plateNumber) => (
                        <TableRow key={plateNumber}>
                          <TableCell>{transformPlateNumber(plateNumber)}</TableCell>
                          <TableCell>{inactiveDates[plateNumber]?.join(", ")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={Math.ceil(inactivePlates.length / platesPerPage)}
                  page={inactivePage}
                  onChange={(_, value) => setInactivePage(value)}
                  sx={{ mt: 2 }}
                  shape="rounded"
                />
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default ActiveCarsPage;
