
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  TableContainer,
  Paper,
  CircularProgress,
  Alert,
  TablePagination,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; 
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";


import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PeopleIcon from '@mui/icons-material/People';
import TrainIcon from '@mui/icons-material/Train';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const TodayTicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDateRange, setSelectedDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]); 
  const [filterType, setFilterType] = useState("daily"); 

  const collections = {
    tickets_getout: "Modjo",
    tickets_getout_Alemgena: "Alemgena",
    tickets_getout_sebeta: "Sebeta",
    tickets_getout_CheffeDonsa: "Cheffe-Donsa",
    tickets_getout_leman: "Lemmen",
    tickets_getout_welliso: "Welliso",
    tickets_getout_Tajji: "Tajji",
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let allTickets = [];

        for (const [collectionName, route] of Object.entries(collections)) {
          const datePaths = getDatePaths(selectedDateRange, filterType);

          for (const datePath of datePaths) {
            const docRef = doc(db, collectionName, datePath);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const data = docSnap.data();
              const ticketsData = Object.values(data).map((ticket, index) => ({
                ...ticket,
                id: `${route}-${index}`, 
                route: route,
              }));
              allTickets = [...allTickets, ...ticketsData];
            }
          }
        }

        setTickets(allTickets);
        filterTickets(searchTerm, selectedRoute, allTickets); 
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedDateRange, filterType]);

  const getDatePaths = (dateRange, filterType) => {
    const startDate = dayjs(dateRange[0]);
    const endDate = dayjs(dateRange[1]);

    let datePaths = [];

    switch (filterType) {
      case "week":
      case "month":
      case "year":
      case "specific":
        for (let d = startDate; d.isBefore(endDate) || d.isSame(endDate); d = d.add(1, "day")) {
          datePaths.push(d.format("YYYY-MM-DD"));
        }
        break;
      default:
        datePaths.push(dayjs().format("YYYY-MM-DD"));
        break;
    }

    return datePaths;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterTickets(event.target.value, selectedRoute);
  };

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
    filterTickets(searchTerm, event.target.value);
  };

  const handleDateRangeChange = (newValue) => {
    setSelectedDateRange(newValue);
  };

  const filterTickets = (search, route, allTickets = tickets) => {
    let filtered = [...allTickets];

    if (route !== "all") {
      filtered = filtered.filter((ticket) => ticket.route === route);
    }

    if (search.trim() !== "") {
      const searchLower = search.toLowerCase();
      filtered = filtered.filter((ticket) =>
        Object.values(ticket)
          .join(" ")  
          .toLowerCase()
          .includes(searchLower)
      );
    }

    setFilteredTickets(filtered);
    setPage(0);
  };

  const getNestedValue = (obj, key) => {
    if (typeof obj === 'object' && obj !== null) {
      if (obj[key] !== undefined) {
        return parseFloat(obj[key]) || 0;
      }
      return Object.values(obj).reduce((acc, value) => acc + getNestedValue(value, key), 0);
    }
    return 0;
  };

  
  const totalTickets = tickets.length;
  const totalTicketsForRoute = filteredTickets.length;
  const totalSeatCapacity = filteredTickets.reduce(
    (acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10),
    0
  );
  const totalkm = filteredTickets.reduce(
    (acc, ticket) => acc + parseFloat(ticket["Distance KM"] || 0, 10),
    0
  );
  const totalServiceCharge = filteredTickets.reduce(
    (acc, ticket) => acc + getNestedValue(ticket, "Service Charge"),
    0
  );
  const uniqueStartStations = Array.from(
    new Set(filteredTickets.map((ticket) => ticket["Start Station"]))
  );
  const totalStartStations = uniqueStartStations.length;
  const totalCharge = filteredTickets.reduce(
    (acc, ticket) => acc + getNestedValue(ticket, "Total Charge"),
    0
  );
  const totalPrice = filteredTickets.reduce(
    (acc, ticket) => acc + getNestedValue(ticket, "Total Price"),
    0
  );

  const columns = [
    { field: "ID", headerName: "ID", width: 150 },
    { field: "Association", headerName: "Association", width: 150 },
    { field: "Destination", headerName: "Destination", width: 150 },
    { field: "Distance KM", headerName: "Distance KM", width: 150 },
    { field: "Level", headerName: "Level", width: 100 },
    { field: "Net Price", headerName: "Net Price", width: 150 },
    { field: "Plate Number", headerName: "Plate Number", width: 150 },
    { field: "Price", headerName: "Price", width: 150 },
    { field: "Route", headerName: "Route", width: 150 },
    { field: "Sales Name", headerName: "Sales Name", width: 150 },
    { field: "Seat Capacity", headerName: "Seat Capacity", width: 150 },
    { field: "Service Charge", headerName: "Service Charge", width: 150 },
    { field: "Start Station", headerName: "Start Station", width: 150 },
    { field: "Total Charge", headerName: "Total Charge", width: 150 },
    { field: "Total Price", headerName: "Total Price", width: 150 },
    { field: "userCode", headerName: "User Code", width: 150 },
    { field: "Address", headerName: "Address", width: 150 },
  ];

  const data = {
    labels: Object.values(collections),
    datasets: [
      {
        label: "Total Tickets",
        data: Object.values(collections).map(
          (route) =>
            tickets.filter((ticket) => ticket.route === route).length
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Total Passengers",
        data: Object.values(collections).map((route) =>
          tickets
            .filter((ticket) => ticket.route === route)
            .reduce((acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10), 0)
        ),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
      {
        label: "Total Price",
        data: Object.values(collections).map((route) =>
          tickets
            .filter((ticket) => ticket.route === route)
            .reduce((acc, ticket) => acc + getNestedValue(ticket, "Total Price"), 0)
        ),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
      },
    ],
  };
  const pieData = {
    labels: Object.values(collections),
    datasets: [
      {
        label: "Total Tickets",
        data: Object.values(collections).map(
          (route) =>
            tickets.filter((ticket) => ticket.route === route).length
        ),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
      },
    ],
  }; 
  const calculateRouteMetrics = (ticketsToAnalyze = filteredTickets) => {
    return Object.values(collections).map((route) => {
      const routeTickets = ticketsToAnalyze.filter((ticket) => ticket.route === route);
      const totalTickets = routeTickets.length;
      const totalPassengers = routeTickets.reduce(
        (acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10),
        0
      );
      const totalDistanceKM = routeTickets.reduce(
        (acc, ticket) => acc + parseFloat(ticket["Distance KM"] || 0),
        0
      );

      // If total distance is 0, multiply total tickets by 20
      const calculatedDistance = totalDistanceKM === 0 
        ? (totalTickets * 20).toFixed(2) 
        : totalDistanceKM.toFixed(2);

      return {
        route,
        totalTickets,
        totalPassengers,
        totalDistanceKM: calculatedDistance
      };
    });
  };

  // Generate CSV data for route metrics
  const routeMetricsCSVData = [
    ["Route", "Total Tickets", "Total Passengers", "Total Distance (KM)"],
    ...calculateRouteMetrics().map((metric) => [
      metric.route,
      metric.totalTickets,
      metric.totalPassengers,
      metric.totalDistanceKM
    ])
  ];

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Tickets Page
      </Typography>

      <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          sx={{ marginRight: 2 }}
        />

        <TextField
          select
          label="Route"
          value={selectedRoute}
          onChange={handleRouteChange}
          variant="outlined"
          size="small"
          sx={{ marginRight: 2 }}
        >
          <MenuItem value="all">All Routes</MenuItem>
          {Object.values(collections).map((route) => (
            <MenuItem key={route} value={route}>
              {route}
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={selectedDateRange}
            onChange={(newValue) => setSelectedDateRange(newValue)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size="small" sx={{ marginRight: 2 }} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} size="small" />
              </>
            )}
          />
        </LocalizationProvider>

        <TextField
          select
          label="Filter Type"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ marginRight: 2 }}
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="week">Weekly</MenuItem>
          <MenuItem value="month">Monthly</MenuItem>
          <MenuItem value="year">Yearly</MenuItem>
          <MenuItem value="specific">Specific Date Range</MenuItem>
        </TextField>

        <CSVLink
          data={filteredTickets}
          filename={`tickets_${selectedDateRange[0].format("YYYY-MM-DD")}_to_${selectedDateRange[1].format("YYYY-MM-DD")}.csv`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" color="primary">
            Export CSV
          </Button>
        </CSVLink>
      </Box>

      <Card sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Summary
        </Typography>
        <Box display="flex" justifyContent="space-around">
          <Box textAlign="center">
            <ConfirmationNumberIcon color="primary" />
            <Typography variant="body1">Total Tickets: {totalTickets}</Typography>
          </Box>
          <Box textAlign="center">
            <PeopleIcon color="primary" />
            <Typography variant="body1">Total Passengers: {totalSeatCapacity}</Typography>
          </Box>
          <Box textAlign="center">
            <PeopleIcon color="primary" />
            <Typography variant="body1">Total Distance KM: {totalkm}</Typography>
          </Box>
          <Box textAlign="center">
            <TrainIcon color="primary" />
            <Typography variant="body1">Total Stations: {totalStartStations}</Typography>
          </Box>
          <Box textAlign="center">
            <AttachMoneyIcon color="primary" />
            <Typography variant="body1">Total Charge: ${totalCharge.toFixed(2)}</Typography>
          </Box>
          <Box textAlign="center">
            <AttachMoneyIcon color="primary" />
            <Typography variant="body1">Total Price: ${totalPrice.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Card>

      <Card sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Total Tickets, Passengers, and Price by Route
        </Typography>
        <Bar data={data} />
      </Card>

      <Card sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Total Tickets by Route (Pie Chart)
        </Typography>
        <Pie data={pieData} />
      </Card>

      <TableContainer component={Paper} style={{ width: '100%', height: 400, marginBottom: 20 }}>
        <DataGrid
          rows={filteredTickets}
          columns={columns}
          pageSize={rowsPerPage}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          pagination
          autoHeight
          getRowId={(row) => row.id} 
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTickets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
       <Card sx={{ p: 2, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>
            Route Summary
          </Typography>
          <CSVLink
            data={routeMetricsCSVData}
            filename={`route_summary_${selectedDateRange[0].format("YYYY-MM-DD")}_to_${selectedDateRange[1].format("YYYY-MM-DD")}.csv`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary" size="small">
              Export Route Summary CSV
            </Button>
          </CSVLink>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Route</TableCell>
                <TableCell align="right">Total Cars</TableCell>
                <TableCell align="right">Total Passengers</TableCell>
                <TableCell align="right">Total Distance (KM)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculateRouteMetrics().map((routeMetric) => (
                <TableRow key={routeMetric.route}>
                  <TableCell component="th" scope="row">
                    {routeMetric.route}
                  </TableCell>
                  <TableCell align="right">{routeMetric.totalTickets}</TableCell>
                  <TableCell align="right">{routeMetric.totalPassengers}</TableCell>
                  <TableCell align="right">{routeMetric.totalDistanceKM}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

     
    </Box>
  );
};

export default TodayTicketsPage;







